import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { Button } from '@bytbil/bootstrap-react';

import getBlocketAdminUrl from 'core/getBlocketAdminUrl';
import { ToastContext } from 'context/ToastContext';

import IMPERSONATE_DEALER from './impersonateDealer';

interface Props {
    dealerId: string;
    className?: string;
}

const BLOCKET_ADMIN_URL = getBlocketAdminUrl();

const LoginToDealerButton = ({ dealerId, className }: Props) => {
    const Toaster = useContext(ToastContext);

    const [impersonateDealer, { loading }] = useMutation(IMPERSONATE_DEALER);

    const handleClick = async () => {
        try {
            const result = await impersonateDealer({
                variables: {
                    dealerId
                }
            });

            // const sessionId = result?.data?.impersonateDealer?.session_id;
            const signedToken = result?.data?.impersonateDealer?.signedToken;

            if (signedToken) {
                window.open(`${BLOCKET_ADMIN_URL}/impersonate/dealer?token=${signedToken}`, '_blank');

                return null;
            }

            console.error('Failed to retrieve session_id');

            return Toaster.showToast(`Någt gick fel, det gick inte att verifiera sessionen`, 'danger');
        } catch (e) {
            console.error(e);
            return Toaster.showToast(`Någt gick fel: ${e}`, 'danger');
        }
    };

    return (
        <Button onClick={handleClick} color="link" className={className} disabled={loading}>
            Logga{loading && 'r'} in
        </Button>
    );
};

export default LoginToDealerButton;
