import React from 'react';
import { FormikValues } from 'formik';
import { Card, CardBody } from '@bytbil/bootstrap-react';
import { useLazyQuery } from '@apollo/client';

import PageLayout from 'components/PageLayout';
import LIST_PRICE_SIGN_BY_DEALER from './queries/listPriceSignsByDealer';
import ListPriceSigns from './components/ListPriceSigns';
import CreatePriceSign from './components/CreatePriceSign';
import SearchPriceSign from './components/SearchPriceSign';
import { QueryData } from './types';

interface Variables {
    dealerCode: string;
}

const PriceSigns = () => {
    const [listPriceSignsByDealer, { loading, error, data, refetch }] = useLazyQuery<QueryData, Variables>(
        LIST_PRICE_SIGN_BY_DEALER,
        { fetchPolicy: 'no-cache' }
    );

    const searchPriceSign = ({ dealerCode }: FormikValues) => listPriceSignsByDealer({ variables: { dealerCode } });

    return (
        <PageLayout title="Prisskyltar">
            <div className="d-flex justify-content-between mb-3">
                <p className="m-0">Hantera prisskyltar</p>
                <CreatePriceSign refetch={refetch} />
            </div>
            <Card>
                <CardBody>
                    <SearchPriceSign searchPriceSign={searchPriceSign} loading={loading} />
                </CardBody>
            </Card>
            <ListPriceSigns priceSigns={data?.priceSignsByDealer} error={error} loading={loading} refetch={refetch} />
        </PageLayout>
    );
};

export default PriceSigns;
