import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import { Button } from '@bytbil/bootstrap-react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { ToastContext } from 'context/ToastContext';
import CREATE_PRICE_SIGN from '../mutations/createPriceSign';
import { PriceSignValues, QueryData } from '../types';
import PriceSignForm from './PriceSignForm';

interface Props {
    refetch?: () => Promise<ApolloQueryResult<QueryData>>;
}

const CreatePriceSign = ({ refetch }: Props) => {
    const toaster = useContext(ToastContext);
    const [createPriceSign] = useMutation(CREATE_PRICE_SIGN);

    const [state, setState] = useImmer({
        modalOpen: false
    });

    const toggleModal = () => {
        setState(draft => {
            draft.modalOpen = !state.modalOpen;
        });
    };

    const handleCreatePriceSign = async (values: PriceSignValues) => {
        try {
            await createPriceSign({ variables: { data: values } });
            toaster.showToast('Prisskylten sparades!', 'success');
            if (refetch) {
                refetch();
            }
            toggleModal();
        } catch {
            toaster.showToast('Något gick fel när prisskylten skulle sparas', 'danger', 5000);
        }
    };

    const initialValues = {
        title: '',
        fileName: '',
        format: 's',
        relations: []
    };

    return (
        <div className="d-flex justify-content-end">
            <Button color="success" onClick={toggleModal}>
                <i className="fa fa-plus mr-2" />
                Lägg till prisskylt
            </Button>
            <PriceSignForm
                modalOpen={state.modalOpen}
                closeModal={toggleModal}
                onSubmit={handleCreatePriceSign}
                initialValues={initialValues}
            />
        </div>
    );
};

export default CreatePriceSign;
