import React from 'react';
import Helmet from 'react-helmet-async';

import MainContainer from 'components/MainContainer';
import PriceSigns from 'features/price-signs/index';

const Route = () => (
    <React.Fragment>
        <Helmet>
            <title>Prisskyltar</title>
        </Helmet>
        <MainContainer>
            <PriceSigns />
        </MainContainer>
    </React.Fragment>
);

export default Route;
