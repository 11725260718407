import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Checkbox } from '@bytbil/general-react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from '@bytbil/bootstrap-react';
import { ToastContext } from 'context/ToastContext';
import { QueryData } from '../types';
import DELETE_PRICE_SIGN from '../mutations/deletePriceSign';

interface Props {
    id: string;
    title: string;
    isOpen: boolean;
    toggleDeleteModal: () => void;
    closeMainModal: () => void;
    refetch?: () => Promise<ApolloQueryResult<QueryData>>;
}

interface State {
    checked: boolean;
}

const DeleteModal = ({ id, title, isOpen, toggleDeleteModal, closeMainModal, refetch }: Props) => {
    const toaster = useContext(ToastContext);

    const [state, setState] = useImmer<State>({
        checked: false
    });

    const [deletePriceSign, { loading }] = useMutation(DELETE_PRICE_SIGN);

    const handleDeletePriceSign = async () => {
        try {
            await deletePriceSign({ variables: { id } });
            toaster.showToast('Prisskylten togs bort!', 'success');
            if (refetch) {
                refetch();
            }
            toggleDeleteModal();
            closeMainModal();
        } catch {
            toaster.showToast('Något gick fel när prisskylten skulle tas bort', 'danger', 5000);
        }
    };

    const toggleCheckbox = () => {
        setState(draft => {
            draft.checked = !state.checked;
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggleDeleteModal} centered fade={false} backdropClassName="invisible">
            <ModalHeader toggle={toggleDeleteModal}>Ta bort prisskylt</ModalHeader>
            <ModalBody className="mb-5">
                <p className="mb-5">
                    Du är på väg att ta bort <b>{title}</b>
                </p>
                <Checkbox
                    id="checked"
                    name="checked"
                    onChange={toggleCheckbox}
                    value={state.checked}
                    label="Jag är säker på att jag vill ta bort prisskylten"
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggleDeleteModal} disabled={loading} className="mr-2">
                    Avbryt
                </Button>
                <Button onClick={handleDeletePriceSign} color="danger" loading={loading} disabled={!state.checked}>
                    Ta bort
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteModal;
