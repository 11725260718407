import React from 'react';
import { Formik } from 'formik';
import { string, object } from 'yup';
import { Field } from '@bytbil/general-react';
import { Alert, Button } from '@bytbil/bootstrap-react';

interface Props {
    onSubmit(values: any, { resetForm, setSubmitting }): void;
    statusMessage?: string;
    isNavigating: boolean;
}

const LoginForm = ({ onSubmit, statusMessage, isNavigating }: Props) => (
    <Formik
        onSubmit={onSubmit}
        validationSchema={object().shape({
            username: string()
                .required('Ange din e-postadress')
                .matches(/^[\w.%+-]+@schibsted\.com$/, 'E-postadressen måste sluta med @schibsted.com'),
            password: string().required('Ange ditt lösenord')
        })}
        initialValues={{
            username: '',
            password: ''
        }}
    >
        {({ handleSubmit, handleBlur, handleChange, errors, values, touched, isSubmitting }) => (
            <form onSubmit={handleSubmit} noValidate name="LoginForm">
                <Field
                    name="username"
                    type="text"
                    autoComplete="username"
                    placeholder="fornamn.efternamn@schibsted.com"
                    label="E-post"
                    autoCapitalize="none"
                    value={values.username}
                    touched={touched.username}
                    error={errors.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                />
                <Field
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    placeholder="••••••••"
                    label="Lösenord"
                    value={values.password}
                    touched={touched.password}
                    error={errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                />

                {!isSubmitting && !isNavigating && statusMessage && <Alert color="danger">{statusMessage}</Alert>}

                <div className="form-group mb-0">
                    <Button type="submit" name="login" color="primary" block loading={isSubmitting || isNavigating}>
                        Logga in
                    </Button>
                </div>
            </form>
        )}
    </Formik>
);

export default LoginForm;
