import React from 'react';
import { useImmer } from 'use-immer';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { Card, CardBody, Button, Alert } from '@bytbil/bootstrap-react';
import Loader from 'components/Loader/Loader';
import { PriceSign, QueryData } from '../types';
import EditPriceSign from './EditPriceSign';

interface Props {
    priceSigns?: PriceSign[];
    loading: boolean;
    error: ApolloError | undefined;
    refetch?: () => Promise<ApolloQueryResult<QueryData>>;
}

const EMPTY_PRICE_SIGN = { title: '', fileName: '', format: 's', relations: [] as string[], id: 0 };

const ListPriceSigns = ({ priceSigns, loading, error, refetch }: Props) => {
    const [state, setState] = useImmer({
        modalOpen: false,
        priceSign: EMPTY_PRICE_SIGN
    });

    if (loading) {
        return <Loader autocenter size={3} className="mt-3" />;
    }

    if (error) {
        return (
            <Alert color="danger" className="mt-3">
                Något gick fel när datan skulle hämtas.
            </Alert>
        );
    }

    if (!priceSigns) {
        return null;
    }

    if (!priceSigns.length) {
        return (
            <Alert color="warning" className="mt-3">
                Inga prisskyltar hittades.
            </Alert>
        );
    }

    const handleClick = (priceSign: PriceSign) => {
        setState(draft => {
            draft.modalOpen = true;
            draft.priceSign = priceSign;
        });
    };

    const closeModal = () => {
        setState(draft => {
            draft.modalOpen = false;
            draft.priceSign = EMPTY_PRICE_SIGN;
        });
    };

    return (
        <>
            <Card className="mt-3">
                <CardBody>
                    {priceSigns.map(priceSign => (
                        <div key={priceSign.id}>
                            <Button color="link" onClick={() => handleClick(priceSign)}>
                                {priceSign.title}
                            </Button>
                        </div>
                    ))}
                </CardBody>
            </Card>
            <EditPriceSign
                priceSign={state.priceSign}
                modalOpen={state.modalOpen}
                closeModal={closeModal}
                refetch={refetch}
            />
        </>
    );
};

export default ListPriceSigns;
