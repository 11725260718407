import React, { useContext } from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { ToastContext } from 'context/ToastContext';
import EDIT_PRICE_SIGN from '../mutations/editPriceSign';
import { PriceSign, QueryData } from '../types';
import PriceSignForm from './PriceSignForm';

interface Props {
    priceSign: PriceSign;
    modalOpen: boolean;
    refetch?: () => Promise<ApolloQueryResult<QueryData>>;
    closeModal: () => void;
}

const EditPriceSign = ({ priceSign, modalOpen, refetch, closeModal }: Props) => {
    const toaster = useContext(ToastContext);
    const [editPriceSign] = useMutation(EDIT_PRICE_SIGN);

    const handleEditPriceSign = async (values: PriceSign) => {
        try {
            await editPriceSign({ variables: { data: values } });
            toaster.showToast('Prisskylten uppdaterades!', 'success');
            if (refetch) {
                refetch();
            }
            closeModal();
        } catch {
            toaster.showToast('Något gick fel när prisskylten skulle uppdateras', 'danger', 5000);
        }
    };

    const initialValues = {
        title: priceSign?.title || '',
        fileName: priceSign?.fileName || '',
        format: priceSign?.format || 's',
        relations: priceSign?.relations || [],
        id: priceSign?.id || 0
    };

    return (
        <PriceSignForm
            initialValues={initialValues}
            priceSign={priceSign}
            modalOpen={modalOpen}
            onSubmit={handleEditPriceSign}
            closeModal={closeModal}
            refetch={refetch}
        />
    );
};
export default EditPriceSign;
