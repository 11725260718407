import gql from 'graphql-tag';

export default gql`
    query ListPriceSignsByDealer($dealerCode: String!) {
        priceSignsByDealer(dealerCode: $dealerCode) {
            id
            format
            title
            fileName
            relations
        }
    }
`;
