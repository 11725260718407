import React from 'react';
import { Alert, Container, Row, Col, Card, CardBody } from '@bytbil/bootstrap-react';

interface Props {
    children?: React.ReactNode;
}

const Login = ({ children }: Props) => (
    <main className="mt-4 pt-2 pt-md-4">
        <Container>
            <Row>
                <Col
                    xs={12}
                    lg={{
                        size: 4,
                        offset: 4
                    }}
                >
                    <h1 className="page-title mb-1 cursor-default">Toolbox</h1>
                </Col>
                <Col
                    xs={12}
                    lg={{
                        size: 5,
                        offset: 4
                    }}
                    className="mb-3"
                >
                    <em className="muted cursor-default" style={{ color: '#aab2bd' }}>
                        &ldquo;Förmodligen den bästa verktygslådan i världen&rdquo; - Okänd
                    </em>
                </Col>
            </Row>
            <Row>
                <Col
                    xs={12}
                    lg={{
                        size: 4,
                        offset: 4
                    }}
                >
                    <Alert color="info" className="cursor-default">
                        <strong>Nytt!</strong> Numera loggar du in till Toolbox med ditt nya{' '}
                        <strong>Blocket Admin-konto</strong>. Har du inget konto än? Kontakta{' '}
                        <strong>kundservice</strong> för att få tillgång.
                    </Alert>
                </Col>
            </Row>
        </Container>
        <Container className="card-container">
            <Row>
                <Col
                    xs={12}
                    lg={{
                        size: 4,
                        offset: 4
                    }}
                >
                    <Card className="card-container-card">
                        <CardBody className="border-top-0">{children}</CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </main>
);

export default Login;
