import React from 'react';
import { Formik, FormikValues } from 'formik';
import { string, object } from 'yup';
import { Button, Form } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

interface Props {
    loading: boolean;
    searchPriceSign: (values: FormikValues) => void;
}

const SearchPriceSign = ({ searchPriceSign, loading }: Props) => (
    <Formik
        initialValues={{ dealerCode: '' }}
        onSubmit={searchPriceSign}
        validationSchema={object().shape({
            dealerCode: string().required('Du måste fylla i en handlarkod')
        })}
    >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
                <div className="d-flex align-items-start p-0 col-12 col-sm-6">
                    <Field
                        type="search"
                        name="dealerCode"
                        label="Handlarkod"
                        error={errors.dealerCode}
                        onChange={handleChange}
                        value={values.dealerCode}
                        touched={touched.dealerCode}
                        className="mb-0 pr-0 w-100"
                    />

                    <Button
                        style={{ marginTop: '27px' }}
                        type="submit"
                        color="primary"
                        className="rounded-0 rounded-right"
                        loading={loading}
                    >
                        Sök
                    </Button>
                </div>
            </Form>
        )}
    </Formik>
);

export default SearchPriceSign;
