/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { useImmer } from 'use-immer';

import LoginLayout from './components/LoginLayout';
import LoginForm from './components/LoginForm';

import LOGIN_MUTATION from './mutations/login';

interface LoginSession {
    session_id: string;

    dealers: string[];

    user: {
        first_name: string;
        last_name: string;
    };
}

export const validateCredentials = (username, password) => {
    // Check if username is an email or not
    const isEmail = username.indexOf('@') > -1;

    // Check if password is empty
    const isPasswordEmpty = password === '';

    // Check if username is empty
    const isUsernameEmpty = username === '';

    return {
        isEmail,
        isPasswordEmpty,
        isUsernameEmpty
    };
};

const LoginContainer = () => {
    const [immerState, setImmerState] = useImmer({
        inProp: false,
        statusMessage: '',
        isNavigating: false
    });

    const [login, { loading }] = useMutation<{ login: LoginSession }>(LOGIN_MUTATION);

    useEffect(() => {
        if (loading) {
            setImmerState(draft => {
                draft.statusMessage = '';
            });
        }
    }, [loading, setImmerState]);

    const handleLogin = async (username, password, setSubmitting) => {
        // Check if username is a valid email
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username);

        if (isValidEmail) {
            try {
                setImmerState(draft => {
                    draft.isNavigating = true;
                });

                const response = await login({
                    variables: {
                        email: username,
                        password
                    }
                });

                if (response && response.data && response.data.login && response.data.login.session_id) {
                    // Login successful, dashboard is on the same url so we need to reload the page
                    window.location.reload();
                }
            } catch (e) {
                setSubmitting(false);

                setImmerState(draft => {
                    draft.isNavigating = false;
                    // draft.statusMessage = getCustomError({ e, name: NAME.LOGIN });
                });
            }
        }
    };

    const submitHandler = async ({ username, password }, helpers) => {
        const { setSubmitting } = helpers;

        const { isPasswordEmpty, isUsernameEmpty } = validateCredentials(username, password);

        if (!isUsernameEmpty && !isPasswordEmpty) {
            return handleLogin(username, password, setSubmitting);
        }

        return setSubmitting(false);
    };

    return (
        <>
            <LoginLayout>
                <LoginForm
                    onSubmit={submitHandler}
                    statusMessage={immerState.statusMessage}
                    isNavigating={immerState.isNavigating}
                />
            </LoginLayout>
        </>
    );
};

export default LoginContainer;
