import React from 'react';
import { Formik, FormikValues } from 'formik';
import { useImmer } from 'use-immer';
import { string, object, array } from 'yup';
import { ApolloQueryResult } from '@apollo/client';
import { Field, Select, MultipleValueInput } from '@bytbil/general-react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback } from '@bytbil/bootstrap-react';
import { PriceSign, QueryData } from '../types';
import DeleteModal from './DeleteModal';

interface Props {
    priceSign?: PriceSign;
    initialValues: FormikValues;
    modalOpen: boolean;
    refetch?: () => Promise<ApolloQueryResult<QueryData>>;
    onSubmit: (values: FormikValues) => void;
    closeModal: () => void;
}

interface State {
    deleteModalOpen: boolean;
}

const PriceSignForm = ({ priceSign, modalOpen, initialValues, closeModal, onSubmit, refetch }: Props) => {
    const [state, setState] = useImmer<State>({
        deleteModalOpen: false
    });

    const toggleDeleteModal = () => {
        setState(draft => {
            draft.deleteModalOpen = !state.deleteModalOpen;
        });
    };

    const isEditMode = !!priceSign;

    return (
        <Modal
            isOpen={modalOpen}
            toggle={closeModal}
            centered
            fade={false}
            className={state.deleteModalOpen ? 'invisible' : ''}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={object().shape({
                    title: string().required('Du måste fylla i ett namn'),
                    fileName: string().required('Du måste fylla i ett filnamn'),
                    relations: array().min(1, 'Du måste koppla en handlare till prisskylten')
                })}
            >
                {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue, handleBlur }) => (
                    <>
                        <Form onSubmit={handleSubmit}>
                            <ModalHeader toggle={closeModal}>
                                {isEditMode ? 'Redigera prisskylt' : 'Skapa prisskylt'}
                            </ModalHeader>
                            <ModalBody className="mb-3">
                                <Select
                                    name="format"
                                    label="Format"
                                    error={errors.format}
                                    value={values.format}
                                    options={[
                                        { value: 's', label: 'Stående' },
                                        { value: 'l', label: 'Liggande' }
                                    ]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.format}
                                />

                                <Field
                                    type="text"
                                    label="Namn"
                                    name="title"
                                    placeholder="Prisskylt - mallens namn"
                                    error={errors.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title}
                                    touched={touched.title}
                                />
                                <Field
                                    type="text"
                                    label="Filnamn"
                                    name="fileName"
                                    placeholder="handlarkod/filnamn.pdf"
                                    error={errors.fileName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fileName}
                                    touched={touched.fileName}
                                />
                                <MultipleValueInput
                                    name="relations"
                                    label="Kopplade handlarkoder"
                                    placeholder="handlarkod"
                                    onChange={(key: string) => (value: string) => setFieldValue(key, value)}
                                    value={values.relations}
                                />
                                {errors.relations && (
                                    <FormFeedback className="d-block">{errors.relations}</FormFeedback>
                                )}
                            </ModalBody>
                            <ModalFooter
                                className={`${isEditMode ? 'justify-content-between' : 'justify-content-end'}`}
                            >
                                {isEditMode && <Button onClick={toggleDeleteModal}>Radera prisskylt</Button>}
                                <div>
                                    <Button onClick={closeModal} disabled={isSubmitting} className="mr-2">
                                        Avbryt
                                    </Button>
                                    <Button type="submit" color="success" loading={isSubmitting}>
                                        Spara
                                    </Button>
                                </div>
                            </ModalFooter>
                        </Form>
                        <DeleteModal
                            title={values.title}
                            id={values.id}
                            isOpen={state.deleteModalOpen}
                            toggleDeleteModal={toggleDeleteModal}
                            refetch={refetch}
                            closeMainModal={closeModal}
                        />
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default PriceSignForm;
